import React from 'react'
import Layout from '../components/layout'

const Websites = () => {
    return (
        <Layout>
            <div className="websites-container">
            <h1>Websites</h1>
            <a href="http://www.jasmedical.com/" target="_blank" rel="noopener noreferrer"><img src={require("../images/JAS-Medical.jpg")} alt="JAS Medical Management"/></a>
            <a href="http://kylelunn.rondaedwards.com/" target="_blank" rel="noopener noreferrer"><img src={require("../images/rondaandkyle.jpg")} alt="Wedding Website"/></a>

            <h1>Projects</h1>
            <a href="http://ronda_jammming.surge.sh/" target="_blank" rel="noopener noreferrer"><img src={require("../images/Jammming.png")} alt="Jammming"/></a>
            <a href="http://ronda-ravenous.surge.sh/" target="_blank" rel="noopener noreferrer"><img src={require("../images/Ravenous.png")} alt="Ravenous"/></a>

            <div className="media-links">
               <a href="https://linkedin.com/in/rondalunn/"><img src={require("../images/linkedin.png")} alt="LinkedIn"></img></a> 
               <a href="https://www.instagram.com/rondalunnart/"><img src={require("../images/instagram.png")} alt="Instagram"></img></a>
               <a href="https://github.com/RondaLunn/"><img src={require("../images/github.png")} alt="GitHub"></img></a>
            </div>
            </div>
        </Layout>
    )
}

export default Websites
